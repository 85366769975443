import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const guidesVehicleCableReelTemplate = () => (
  <Layout>
    <SEO title="Размещение барабанов с кабельной продукцией в транспортном средстве" />
    <div>
      <span><Link to={`/guides/`}>Справочник</Link></span>
      <span> > </span>
      <span>Размещение барабанов с кабельной продукцией в транспортном средстве</span>
    </div>
    <main>
    <h1>Размещение барабанов с кабельной продукцией в транспортном средстве</h1>

    <table>
      <thead>
        <tr>
          <th rowSpan="3">Номер барабана</th>
          <th colSpan="5">Количество, штук</th>
        </tr>
        <tr>
          <th colSpan="5">Автомобильный транспорт, (Lхa) м</th>
        </tr>
        <tr>
          <th>8,0 т. (5,4х2,2)</th>
          <th>10,0 т. (6х2,2)</th>
          <th>14,0 т. (9х2,2)</th>
          <th>20,0 т. еврофура п/п (13,5х2,3)</th>
          <th>20,0 т. обычный п/п (12,0х2,2)</th>
        </tr>
      </thead>
      <tbody>
      <tr>
        <td>8а</td>
        <td>24</td>
        <td>30</td>
        <td>44</td>
        <td>64</td>
        <td>52</td>
      </tr>
      <tr>
        <td>10</td>
        <td>15</td>
        <td>18</td>
        <td>27</td>
        <td>38</td>
        <td>30</td>
      </tr>
      <tr>
        <td>12</td>
        <td>12</td>
        <td>15</td>
        <td>21</td>
        <td>32</td>
        <td>26</td>
      </tr>
      <tr>
        <td>12а</td>
        <td>8</td>
        <td>10</td>
        <td>14</td>
        <td>21</td>
        <td>17</td>
      </tr>
      <tr>
        <td>14</td>
        <td>7</td>
        <td>8</td>
        <td>12</td>
        <td>18</td>
        <td>14</td>
      </tr>
      <tr>
        <td>16</td>
        <td>4</td>
        <td>6</td>
        <td>8</td>
        <td>14</td>
        <td>9</td>
      </tr>
      <tr>
        <td>17</td>
        <td>4</td>
        <td>6</td>
        <td>8</td>
        <td>14</td>
        <td>9</td>
      </tr>
      <tr>
        <td>18</td>
        <td>4</td>
        <td>6</td>
        <td>8</td>
        <td>14</td>
        <td>9</td>
      </tr>
      <tr>
        <td>20</td>
        <td>4</td>
        <td>4</td>
        <td>7</td>
        <td>10</td>
        <td>8</td>
      </tr>
      <tr>
        <td>22</td>
        <td>4</td>
        <td>4</td>
        <td>7</td>
        <td>10</td>
        <td>8</td>
      </tr>
      </tbody>
    </table>
    </main>
  </Layout>
)

export default guidesVehicleCableReelTemplate
